@import 'global';

@import 'layout/grid';


* {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    overflow-x: hidden;
    scroll-behavior: smooth;

    ::-moz-selection { /* Code for Firefox */
        color: color('brand', 'light');
        background: color('brand', 'dark');
    }
    
    ::selection {
        color: color('brand', 'light');
        background: color('brand', 'dark');
    }
}

body {
    background-color: color('brand', 'dark');
    scroll-behavior: smooth;
}

.app {
    height: 100vh;

    .sections {
        width: 100%;
        height: calc(100vh - #{$topBarHeight});
        position: relative;
        top: $topBarHeight;
        
        // scroll-snap-type: y mandatory;
        scrollbar-width: none; // Firefox
        &::-webkit-scrollbar { display: none; }

        > section {
            width: 100%;
            min-height: calc(100vh - #{$topBarHeight});
            // height: calc(100vh - #{$topBarHeight});
            // scroll-snap-align: start;
        }
    }
}
