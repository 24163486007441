@mixin xs {
    @media (max-width: screen-width('xs', 'max')) {
        @content;
    }
}

@mixin sm {
    @media (min-width: screen-width('sm', 'min')) {
        @content;
    }
}

@mixin md {
    @media (min-width: screen-width('md', 'min')) {
        @content;
    }
}

@mixin lg {
    @media (min-width: screen-width('lg', 'min')) {
        @content;
    }
}

@mixin xl {
    @media (min-width: screen-width('xl', 'min')) {
        @content;
    }
}

@mixin xxl {
    @media (min-width: screen-width('xxl', 'min')) {
        @content;
    }
}
